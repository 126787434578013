import React from "react"

function GlobeIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 18.3333C14.6022 18.3333 18.3332 14.6023 18.3332 9.99996C18.3332 5.39759 14.6022 1.66663 9.99984 1.66663C5.39746 1.66663 1.6665 5.39759 1.6665 9.99996C1.6665 14.6023 5.39746 18.3333 9.99984 18.3333Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 10H18.3332"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99984 1.66663C12.0842 3.94859 13.2688 6.90999 13.3332 9.99996C13.2688 13.0899 12.0842 16.0513 9.99984 18.3333C7.91544 16.0513 6.73088 13.0899 6.6665 9.99996C6.73088 6.90999 7.91544 3.94859 9.99984 1.66663V1.66663Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function MailIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33317 3.33337H16.6665C17.5832 3.33337 18.3332 4.08337 18.3332 5.00004V15C18.3332 15.9167 17.5832 16.6667 16.6665 16.6667H3.33317C2.4165 16.6667 1.6665 15.9167 1.6665 15V5.00004C1.6665 4.08337 2.4165 3.33337 3.33317 3.33337Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3332 5L9.99984 10.8333L1.6665 5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function ShoppingCartIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49984 18.3333C7.96007 18.3333 8.33317 17.9602 8.33317 17.5C8.33317 17.0397 7.96007 16.6666 7.49984 16.6666C7.0396 16.6666 6.6665 17.0397 6.6665 17.5C6.6665 17.9602 7.0396 18.3333 7.49984 18.3333Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6668 18.3333C17.1271 18.3333 17.5002 17.9602 17.5002 17.5C17.5002 17.0397 17.1271 16.6666 16.6668 16.6666C16.2066 16.6666 15.8335 17.0397 15.8335 17.5C15.8335 17.9602 16.2066 18.3333 16.6668 18.3333Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.833496 0.833374H4.16683L6.40016 11.9917C6.47637 12.3754 6.68509 12.72 6.98978 12.9653C7.29448 13.2106 7.67574 13.3409 8.06683 13.3334H16.1668C16.5579 13.3409 16.9392 13.2106 17.2439 12.9653C17.5486 12.72 17.7573 12.3754 17.8335 11.9917L19.1668 5.00004H5.00016"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

function getSiteIconByType(type) {
  switch (type) {
    case "globe":
      return <GlobeIcon />
    case "mail":
      return <MailIcon />
    case "shopping-cart":
      return <ShoppingCartIcon />
    default:
      return null
  }
}

export default function SiteIcon({ type, className, style }) {
  return (
    <i className={className} style={style}>
      {getSiteIconByType(type)}
    </i>
  )
}
