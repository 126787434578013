export function em(value, base = 16) {
  return `${value / base}em`
}

export function rem(value, base = 16) {
  return `${value / base}rem`
}

export function fluidValue(minValue, maxValue, minWidth, maxWidth) {
  return `
    calc(${rem(minValue)} + ${maxValue - minValue} * ((100vw - ${rem(minWidth)}) / ${maxWidth - minWidth}));
  `
}
