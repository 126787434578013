import { createGlobalStyle, keyframes } from "styled-components"
import "../fonts/fonts.css"
import theme from "./theme"

const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export default createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /*
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /*
    Optionally: Define a strong focus indicator for keyboard focus.
    If you choose to skip this step then the browser's default focus
    indicator will be displayed instead.
  */
  .js-focus-visible .focus-visible {}

  /*
    Optionally: Define text selection color and background-color
  */
 ::selection {
    color: ${theme.colors.white};
    background-color: ${theme.colors.accent};
  }

  /*
    https://css-tricks.com/almanac/properties/s/scrollbar/
  */
  /* ::-webkit-scrollbar {
    width: ;
    height: ;
  }
  ::-webkit-scrollbar-track {
    background-color: ;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ;

    &:focus {
      outline: none;
    }
  } */

  /*
    Disable Firefox dotted focus outline
  */
  :-moz-focusring {
    outline: none;
  }

  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    color: ${theme.colors.variants.default.text.body};

    scroll-behavior: smooth;
  }

  body {
    background-color: ${theme.colors.variants.default.background.default};
    font-family: "GeometricaNarrow", sans-serif;
    margin: 0;
    overflow-x: hidden;

    &.scroll--disabled {
      overflow: hidden;
    }

    &.fade-dark::after {
      content: "";
      position: fixed;
      top: 0;
      right: 0%;
      bottom: 0;
      left: 0;
      z-index: 105;
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 0;
      animation: ${fadein} 250ms linear forwards;
      animation-delay: 100ms;
    }
  }

  a {
    color: inherit;
    display: inline-block;
    margin: -0.5em;
    padding: 0.5em;
  }

  a,
  button {
    &:focus {
      outline-color: ${theme.colors.accent};
    }
  }

  svg {
    display: block;
  }
`
