export default {
  colors: {
    black: "#000",
    white: "#FFF",

    accent: "#C8102E",

    variants: {
      default: {
        name: "light",

        background: {
          default: "#F6F6F6",
          highlight: "#FFFFFF",
        },

        text: {
          heading: "#2D2D2D",
          body: "#4E4E4E",
        },
      },

      dark: {
        name: "dark",

        background: {
          default: "#1D1D1D",
          highlight: "#323232",
        },

        text: {
          heading: "#E6E6E6",
          body: "#BABABA",
        },
      },
    },
  },

  motion: {
    contentVariants: {
      initial: { opacity: 0, y: 20 },
      enter: ({ delay } = {}) => ({
        opacity: 1,
        y: 0,
        transition: {
          delay: delay || 0,
          type: "spring",
          damping: 50,
          stiffness: 150,
        },
      }),
    },
  },

  screens: {
    sm: 768,
    md: 1024,
    lg: 1440,

    custom: {
      mobile: 450,
    },
  },
};
