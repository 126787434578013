import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
import Footer from "./Footer"
import { LanguageContext } from "../../core/context/language-context"
import data from "../../data/kajene.json"

// export const kajeneQuery = graphql`
//   query Kajene {
//     allDataJson(filter: { kajene: { id: { eq: "kajene" } } }) {
//       edges {
//         node {
//           kajene {
//             kontakti_bloks {
//               virsraksts
//               kontakti {
//                 virsraksts
//                 saite {
//                   teksts
//                   url
//                 }
//               }
//             }

//             seko_mums_bloks {
//               virsraksts
//               platformas {
//                 id
//                 ikona {
//                   id
//                 }
//                 saite {
//                   title
//                   url
//                 }
//               }
//             }

//             saites_bloks {
//               virsraksts
//               saites {
//                 ikona {
//                   id
//                 }
//                 teksts
//                 url
//               }
//             }

//             atbildiga_lietosana {
//               virsraksts
//               teksts
//               cta {
//                 teksts
//                 url
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

export default function FooterCell() {
  // const data = useStaticQuery(kajeneQuery)
  const { language } = React.useContext(LanguageContext)

  // Required check for no data being returned
  // const doc = data.allDataJson.edges.slice(0, 1).pop()
  // if (!doc) {
  //   console.warn("Problēma kājenes datu integrācijā")
  //   return null
  // }

  const kajene = data.kajene[language]
  return <Footer kajene={kajene} />
}
