import React from "react"
import styled from "styled-components/macro"
import { listReset } from "../../core/reset"
import { rem } from "../../core/utils"
import theme from "../../core/theme"
import SiteIcon from "./SiteIcon"
import SocialIcon from "./SocialIcon"
import ExternalIcon from "./ExternalIcon"

const StyledFooter = styled.footer`
  background-color: ${theme.colors.variants.dark.background.default};
  color: ${theme.colors.variants.dark.text.body};
  line-height: 130%;
  padding-top: ${rem(32)};
  padding-bottom: ${rem(32)};
  position: relative;
  z-index: 10;

  @media (min-width: ${rem(theme.screens.md)}) {
    padding-top: ${rem(56)};
    padding-bottom: ${rem(56)};
  }
`

const Container = styled.div`
  max-width: ${rem(1280)};
  margin-right: auto;
  margin-left: auto;
  padding-right: ${rem(24)};
  padding-left: ${rem(24)};
  width: 100%;

  @media (min-width: ${rem(theme.screens.sm)}) {
    padding-right: ${rem(56)};
    padding-left: ${rem(56)};
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${rem(-16)};

  @media (min-width: ${rem(theme.screens.md)}) {
    justify-content: space-between;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 35ch;
  padding: ${rem(16)};

  > div + div {
    margin-top: ${rem(32)};
  }

  a {
    text-decoration: none;

    &:hover {
      color: ${theme.colors.white};
      text-decoration: underline;
    }
  }

  p {
    margin: 0;
  }

  @media (min-width: ${rem(theme.screens.md)}) {
    > div + div {
      margin-top: ${rem(56)};
    }
  }
`

const VisibleMedium = styled.div`
  display: none;

  @media (min-width: ${rem(theme.screens.md)}) {
    display: block;
  }
`

const HiddenMedium = styled.div`
  @media (min-width: ${rem(theme.screens.md)}) {
    display: none;
  }
`

const Title = styled.h6`
  color: ${theme.colors.variants.dark.text.heading};
  font-size: ${rem(20)};
  margin-top: 0;
  margin-bottom: ${rem(18)};
`

const SocialList = styled.ul`
  ${listReset};

  display: flex;
  flex-wrap: wrap;
  margin: ${rem(-4)};

  > li {
    margin: ${rem(4)};
  }

  a {
    display: inline-block;
    margin: -${rem(8)};
    padding: ${rem(8)};

    > i {
      display: inline-block;
    }
  }

  @media (min-width: ${rem(theme.screens.md)}) {
    max-width: 20ch;
  }
`

const LinksList = styled.ul`
  ${listReset};

  > li + li {
    margin-top: ${rem(12)};
  }

  a {
    display: flex;
    align-items: center;
    margin: -${rem(8)};
    padding: ${rem(8)};

    > i {
      display: inline-block;
      margin-right: ${rem(8)};
    }
  }
`

const ExternalLink = styled.a`
  border: 1px solid currentColor;
  border-radius: ${rem(5)};
  display: flex;
  align-items: center;
  margin-top: ${rem(24)};
  margin-left: 0;
  padding: ${rem(16)} ${rem(32)};
  text-decoration: none;

  > i {
    display: inline-block;
    margin-left: ${rem(8)};
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
    color: ${theme.colors.white};
    text-decoration: none !important;
  }
`

const StyledKontaktiBloks = styled.div`
  p {
    color: ${theme.colors.white};
  }
`

const KontaktiBloks = React.memo(function KontaktiBloks({ data }) {
  const { virsraksts, kontakti } = data
  return (
    <StyledKontaktiBloks>
      <Title>{virsraksts}</Title>
      {kontakti.map((kontakti, i) => {
        const { virsraksts, saite } = kontakti
        return (
          <React.Fragment key={`kontakti_${i + 1}`}>
            <p>{virsraksts}</p>
            <a href={saite.url} target="_self">
              <strong>{saite.teksts}</strong>
            </a>
          </React.Fragment>
        )
      })}
    </StyledKontaktiBloks>
  )
})

const SekoMumsBloks = React.memo(function SekoMumsBloks({ data }) {
  const { virsraksts, platformas } = data
  return (
    <>
      <Title>{virsraksts}</Title>
      <SocialList>
        {platformas.map(({ id, ikona, saite }) =>
          saite ? (
            <li key={id}>
              <a
                href={saite.url}
                target="_blank"
                rel="noreferrer noopener"
                title={saite.title}
              >
                <SocialIcon type={ikona.id} />
              </a>
            </li>
          ) : null
        )}
      </SocialList>
    </>
  )
})

const SaitesBloks = React.memo(function SaitesBloks({ data }) {
  const { virsraksts, saites } = data
  return (
    <>
      <Title>{virsraksts}</Title>
      <LinksList>
        {saites.map(({ ikona, teksts, url }) =>
          url ? (
            <li key={url}>
              <a href={url} target="_blank" rel="noreferrer noopener">
                <SiteIcon type={ikona.id} /> {teksts}
              </a>
            </li>
          ) : null
        )}
      </LinksList>
    </>
  )
})

const AtbildigaLietosana = React.memo(function AtbildigaLietosana({ data }) {
  const { virsraksts, teksts, cta } = data
  return (
    <>
      <Title>{virsraksts}</Title>
      <p>{teksts}</p>
      <ExternalLink href={cta.url} target="_blank" rel="noreferrer noopener">
        {cta.teksts} <ExternalIcon />
      </ExternalLink>
    </>
  )
})

export default React.memo(function Footer({ kajene }) {
  return (
    <StyledFooter>
      <Container>
        <Row>
          <Column>
            <KontaktiBloks data={kajene.kontakti_bloks} />
            <VisibleMedium>
              <SekoMumsBloks data={kajene.seko_mums_bloks} />
            </VisibleMedium>
          </Column>

          <Column>
            <SaitesBloks data={kajene.saites_bloks} />
          </Column>

          <Column>
            <AtbildigaLietosana data={kajene.atbildiga_lietosana} />
          </Column>

          <HiddenMedium>
            <Column>
              <SekoMumsBloks data={kajene.seko_mums_bloks} />
            </Column>
          </HiddenMedium>
        </Row>
      </Container>
    </StyledFooter>
  )
})

