import React from "react"

export default function ExternalIcon() {
  return (
    <i>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 12.6V17.4C17 17.8243 16.8314 18.2313 16.5314 18.5314C16.2313 18.8314 15.8243 19 15.4 19H6.6C6.17565 19 5.76869 18.8314 5.46863 18.5314C5.16857 18.2313 5 17.8243 5 17.4V8.6C5 8.17565 5.16857 7.76869 5.46863 7.46863C5.76869 7.16857 6.17565 7 6.6 7H11.4"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 5H19V10"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 14L19 5"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </i>
  )
}
