import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { BrowserRouter as Router } from "react-router-dom"
import GlobalStyle from "../core/GlobalStyle"
import { LanguageProvider } from "../core/context/language-context"

import FooterCell from "../components/Footer/FooterCell"
import CookiesConsentProxy from "../components/CookiesConsentProxy"

export const tulkojumiQuery = graphql`
  query Tulkojumi {
    allStrapiTulkojumi {
      edges {
        node {
          tulkojumi {
            uid
            teksts__lv
            # teksts__ru
            teksts__en
          }
        }
      }
    }
  }
`

export default function Layout({ pageContext, innerRef, children }) {
  const contextLang = pageContext && pageContext.lang && pageContext.lang.id

  const data = useStaticQuery(tulkojumiQuery)
  const tulkojumi = data.allStrapiTulkojumi.edges.slice(0, 1).pop()

  return (
    <>
      <GlobalStyle />

      {/* <Router> */}
      <LanguageProvider
        contextLang={contextLang}
        tulkojumi={tulkojumi.node.tulkojumi}
      >
        <div ref={innerRef}>{children}</div>

        <FooterCell />
        <CookiesConsentProxy />
      </LanguageProvider>
      {/* </Router> */}
    </>
  )
}
