import React from "react"
import { LanguageContext, languages } from "./context/language-context"

// FIXME: LanguageContext satur tulkojums() tekstu izgūšanai pēc ID,
// mulsinoši, ka līdzīgi nosaukta funkcija pavisam citam pielietojumam

export function translate(data, lang, langs = languages) {
  const newData = {}
  Object.keys(data).forEach(key => {
    const lastIndexOfUnderline = key.lastIndexOf("__")
    if (lastIndexOfUnderline > -1) {
      const suffix = key.substr(lastIndexOfUnderline + 2)
      if (langs.includes(suffix)) {
        if (lang === suffix) {
          const keyBase = key.substring(0, lastIndexOfUnderline)
          newData[keyBase] = data[key]
        } // else - key from alternate language
      } else {
        // different suffix
        newData[key] = data[key]
      }
    } else {
      // "__" not present
      newData[key] = data[key]
    }
  })
  return newData
}

export function useTranslate(data) {
  const { language } = React.useContext(LanguageContext)
  return translate(data, language)
}
