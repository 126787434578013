import { css } from "styled-components";

export const listReset = css`
  list-style: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
`;

export const buttonReset = css`
  appearance: none;
  background-color: transparent;
  border: none;
  font: inherit;
  line-height: 1em;
  padding: 1em;
`;
