import React from "react"
import { Helmet } from "react-helmet"
// import styled from "styled-components"
// import { rem } from "../core/utils"
// import { buttonReset } from "../core/reset"
// import { LanguageContext } from "../core/context/language-context"

// const Badge = styled.button.attrs({ type: "button" })`
//   ${buttonReset};

//   position: fixed;
//   right: ${rem(4)};
//   bottom: ${rem(4)};
//   z-index: 100;

//   background-color: rgba(0, 0, 0, 0.5);
//   color: #fff;
//   font-size: ${rem(14)};
//   padding: ${rem(6)} ${rem(12)};

//   &:focus {
//     outline: 0;
//   }
// `

// LMT izstrādāts centralizēts Sīkdatņu paziņojums

function waitForGlobal(name, timeout = 500) {
  return new Promise((resolve, reject) => {
    let waited = 0

    function wait(interval) {
      setTimeout(() => {
        waited += interval
        if (window[name] !== undefined) {
          return resolve()
        }
        if (waited >= timeout * 1000) {
          return reject({ message: "Timeout" })
        }
        wait(interval * 2)
      }, interval)
    }

    wait(25)
  })
}

export default function CookiesConsentProxy() {
  // const { tulkojums } = React.useContext(LanguageContext)
  const [scriptLoaded, setScriptLoaded] = React.useState(false)

  React.useEffect(() => {
    if (window) {
      if (window.Cookies && window.Cookies.isStatisticsCookieAllowed) {
        setScriptLoaded(true)
      }
      waitForGlobal("CookiesInit").then(() => {
        window.CookiesInit.scriptInit()
        waitForGlobal("Cookies").then(() => {
          setScriptLoaded(true)
        })
      })
    }
  }, [])

  const [cookiesOptIn, setCookiesOptIn] = React.useState({
    functional: true,
    analytical: false,
    marketing: false,
  })
  React.useEffect(() => {
    if (scriptLoaded && window.Cookies) {
      setCookiesOptIn({
        functional: window.Cookies.isTechnicalCookieAllowed(),
        analytical: window.Cookies.isStatisticsCookieAllowed(),
        marketing: window.Cookies.isMarketingCookieAllowed(),
      })
    }
  }, [scriptLoaded])

  const scripts = React.useMemo(() => {
    return process.env.NODE_ENV === "production" ? (
      <>
        {cookiesOptIn.analytical && (
          <Helmet>
            {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
            <script
              key="gtag-ua"
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-5721114-68"
            />
            <script key="gtag-ua-inline">{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-5721114-68');
          `}</script>
          </Helmet>
        )}

        {cookiesOptIn.marketing && (
          <>
            <Helmet>
              {/* <!-- Global site tag (gtag.js) - Google Ads: 997958613 --> */}
              <script
                key="gtag-aw"
                async
                src="https://www.googletagmanager.com/gtag/js?id=AW-997958613"
              />
              <script key="gtag-aw-inline">{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'AW-997958613');
            `}</script>
            </Helmet>

            <Helmet>
              {/* <!-- Google Tag Manager --> */}
              <script key="gtm-inf-inline">{`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f
              );
              })(window,document,'script','dataLayer','GTM-PMRQ656');
              `}</script>
            </Helmet>

            <Helmet>
              {/* salespoints.co */}
              <script
                key="salespoints"
                async
                src="https://foxy.salespoints.co/?script=foxy_1038"
              />
            </Helmet>

            <Helmet>
              {/* Hotjar Tracking Code for www.lmt.lv */}
              <script key="hotjar-inline">{`
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2203716,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `}</script>
            </Helmet>
          </>
        )}
      </>
    ) : undefined
  }, [cookiesOptIn.analytical, cookiesOptIn.marketing])

  const cookiesVersion = Math.floor(Date.now() / 1000 / 60 / 15)

  return typeof window !== "undefined" ? (
    <>
      <Helmet>
        <link
          key="lmt-cookies-style"
          href={`https://cookies.lmt.lv/css/cookie.css?v=${cookiesVersion}`}
          media="all"
          rel="stylesheet"
          type="text/css"
        />
        <script
          key="lmt-cookies-script"
          type="text/javascript"
          src={`https://cookies.lmt.lv/js/cookie.js?v=${cookiesVersion}`}
        />
      </Helmet>

      {scripts}

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-PMRQ656"
          title="Google Tag Manager (noscript)"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}
    </>
  ) : null
}
