/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { LanguageContext } from "../core/context/language-context"
import { translate } from "../core/helpers"

function SEO({ description, lang, meta, title }) {
  const { language } = React.useContext(LanguageContext)
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              # title
              # description
              # image
              author
              domain
            }
            buildTime(locale: "en")
          }
          strapiSeo {
            virsraksts__lv
            # virsraksts__ru
            virsraksts__en
            apraksts__lv
            # apraksts__ru
            apraksts__en
            bilde__lv {
              publicURL
            }
            # bilde__ru {
            #   publicURL
            # }
            bilde__en {
              publicURL
            }
          }
        }
      `}
      render={({ site, strapiSeo }) => {
        const seo = translate(strapiSeo, language)
        const metaTitle = seo.virsraksts || site.siteMetadata.title
        const metaDescription = seo.apraksts || site.siteMetadata.description
        const metaImage = seo.bilde
          ? `${site.siteMetadata.domain}${seo.bilde.publicURL}`
          : site.siteMetadata.image
        return (
          <Helmet
            htmlAttributes={{
              lang: language,
            }}
            title={metaTitle}
            // titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                name: "developer",
                content: "Raimonds Sarkanbardis, raimonds@wrong.lv",
              },
              {
                name: "revised",
                content: site.buildTime,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                name: `og:image`,
                content: metaImage,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `facebook-domain-verification`,
                content: "744j4yuumy0fwpw8zxcbam68sfp96p",
              },
            ].concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `lv`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  // title: PropTypes.string.isRequired,
}

export default SEO
