import React from "react"
import { translate } from "../helpers"

export const defaultLanguage = "lv"
export const languages = [
  "lv",
  // krievu valoda deaktivizēta pēc klienta pieprasījuma
  // "ru",
  "en",
]

export const LanguageContext = React.createContext({
  language: defaultLanguage,
  changeLanguage: () => {},
  tulkojumi: [],
  tulkojums: () => {},
})

export function LanguageProvider({ contextLang, children, tulkojumi = [] }) {
  const [language, setlanguage] = React.useState(
    () => contextLang || defaultLanguage
  )
  // console.log('Language', language)

  const tulkotiTulkojumi = React.useMemo(
    () => tulkojumi.map(node => translate(node, language)),
    [language, tulkojumi]
  )

  function changeLanguage(newLanguage) {
    if (languages.includes(newLanguage)) {
      setlanguage(newLanguage)
    } else {
      console.warn(`Language '${newLanguage}' is not intended`)
    }
  }

  function tulkojums(id) {
    const tulkojums = tulkotiTulkojumi.find(({ uid }) => uid === id)
    if (!tulkojums) {
      console.warn(`Nav tulkojuma '${id}'`)
      return id
    }
    return tulkojums.teksts
  }

  return (
    <LanguageContext.Provider
      value={{
        contextLang,
        language,
        changeLanguage,
        tulkojums,
        tulkojumi: tulkotiTulkojumi,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}
